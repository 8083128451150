@use "variables";

/* ==========================================================================
   Base Styles (Mobile First)
   ========================================================================== */

body {
    margin: 0;
    padding: 0; /* No base padding; controlled by container or specific elements */
    font-family: sans-serif;
    /* Background image relative to compiled CSS in /assets/css/ */
    background-image:
        linear-gradient(transparent, transparent),
        url("../images/fishbowld_back.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: scroll; /* Scrolls with content on mobile */
    display: flex; /* Used for vertical centering/layout */
    flex-direction: column;
    align-items: center; /* Center direct children like .desktop-layout-container */
    min-height: 100vh; /* Ensure body takes at least full viewport height */
    box-sizing: border-box; /* Include padding/border in element's total width/height */
}

/* Default link styling (if needed) */
a {
    text-decoration: none; /* Optional: remove default underline */
    color: inherit; /* Optional: inherit text color */
}

/* Common Image Styling Reset */
img {
    display: block; /* Remove extra space below inline images */
    height: auto; /* Maintain aspect ratio */
    max-width: 100%; /* Prevent images from overflowing their containers by default */
}

.contact-desktop {
    display: none; // Hidden on mobile by default
}

.contact-mobile {
    display: block; // Shown on mobile by default
}

.contact-email {
    font-family: "adobe-garamond-pro", serif;
    font-style: oblique;
    font-weight: bold;
    transform: skewX(-12deg);
    font-size: clamp(0.4rem, 2.7vw, 18px);
    line-height: 1.2;
    letter-spacing: 0.2em;
    color: white;
    text-align: center;
    margin-top: 3rem;
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

    .contact-label {
        font-style: oblique;
        transform: skewX(-15deg);
        color: white;
        text-decoration: none;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
    }

    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}

/* ==========================================================================
   Layout Structure (Mobile Defaults)
   ========================================================================== */

/* This container wraps the three columns but is only actively used for flex layout on desktop */
.desktop-layout-container {
    width: 100%;
    display: block; /* Acts as a normal block element on mobile */
}

/* Column Defaults (Mobile: Stacked Vertically) */
.main-content-column,
.social-links-column,
.youtube-column {
    width: 100%; /* Take full width when stacked */
    box-sizing: border-box;
}

/* Specific columns hidden/styled differently on mobile by default */
.order-here,
.youtube-column {
    display: none; /* Hidden on mobile, shown via media queries */
}

.purchase-stream-divider-container {
    display: block; /* Shown only on mobile */
    text-align: center;
    width: 100%;
    margin: 0px auto 0px auto; /* Vertical spacing, centered horizontally */
    overflow: hidden; /* Hides the parts of the wide image */
}

/* ==========================================================================
   Content Elements (Mobile Defaults)
   ========================================================================== */

/* --- Main Content Column Elements --- */

.album-announcement {
    text-align: center;
    margin: 30px auto 0 auto;
    transition: transform 0.3s ease;

    picture {
        display: inline-block; // Ensures the picture behaves like an inline element

        img {
            max-width: 80%; // Specific mobile size, or adjust as needed
            margin: 20px auto;
            height: auto; // Maintain aspect ratio

            // Existing hover effect
            &:hover {
                transform: scale(1.05);
            }
        }
    }
}

.album-cover-container {
    text-align: center;
    margin: 0px auto 50px auto; /* Centered */
    transition: transform 0.3s ease;

    img {
        max-width: 90%; /* Specific mobile size */
        margin: 0 auto;
    }
    &:hover {
        transform: scale(1.05);
    }
}

.purchase-stream-divider {
    picture img {
        width: 350%;
        max-width: none;
        object-fit: cover;
        object-position: center;
        margin-left: -125%;
        transition: transform 0.2s ease;

        &:hover {
            transform: scale(1.1) translateY(-5px);
        }
    }
}

/* Container for purchase links */
.purchase-links-container {
    text-align: center;
    margin: 0 auto; /* Centered */
}

/* Flex container for the actual links */
.purchase-links {
    display: flex;
    justify-content: center;
    align-items: center; /* Align items vertically if they wrap */
    flex-wrap: wrap;
    gap: 10px; /* Consistent gap */
    padding: 10px 0; /* Vertical padding */
}

.purchase-links a img {
    width: variables.$purchase-icon-size-mobile;
    height: variables.$purchase-icon-size-mobile; /* Maintain square shape */
    max-width: 150px; /* Upper limit for very small screens if needed */
    object-fit: contain; /* Ensure icon fits without distortion */
    transition: transform 0.2s ease;

    &:hover {
        transform: scale(1.1) translateY(-5px);
    }
}

/* --- Social Links Column Elements --- */

.social-links-container {
    text-align: center;
    width: 90%; /* Slightly narrower than full width on mobile */
    margin: 20px auto; /* Vertical spacing, centered */
    padding-top: 30px; /* Space above content */
}

.follow-us {
    padding-bottom: 15px; /* Space below "Follow Us" image */
    transition: transform 0.3s ease;

    img {
        max-width: 50%; /* Specific mobile size */
        margin: 0 auto; /* Center the image */
    }
    &:hover {
        transform: scale(1.05);
    }
}

.social-links {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px; /* Consistent gap */
    padding: 5px;
    /* border-radius: 10px; */ /* Optional rounded corners for the group */
}

.social-links a img {
    width: variables.$social-icon-size-mobile;
    height: variables.$social-icon-size-mobile;
    max-width: 80px; /* Upper limit */
    object-fit: contain;
    transition: transform 0.2s ease;

    &:hover {
        transform: rotate(10deg);
    }
}

/* --- YouTube Column Elements (Styles defined here apply if shown on mobile) --- */

.youtube-column {
    /* Base styles are display: none; */
    width: 90%; /* Default width if made visible */
    margin: 30px auto 40px auto; /* Default margins if made visible */

    .video-announcement {
        text-align: center;
        padding: 30px 0 15px 0; // Vertical spacing
        transition: transform 0.3s ease;

        picture {
            display: inline-block; // Ensures the block nature if needed

            img {
                max-width: 80%; // Specific mobile size, and adjusted as needed for other breakpoints
                margin: 0 auto;
                height: auto; // Maintain aspect ratio
                transition: transform 0.2s ease; // Ensure smooth hover effects

                &:hover {
                    transform: scale(1.05); // Slight scaling on hover
                }
            }
        }
    }

    .youtube-container-inner {
        width: 100%; /* Fill the column width */
        border: none; /* No border on mobile */
        animation: none; /* No animation on mobile */
        iframe {
            display: block; /* Remove extra space */
            width: 100%;
            height: auto; /* Use aspect-ratio */
            aspect-ratio: 16 / 9; /* Standard video aspect ratio */
            border: none; /* Remove default iframe border */
        }
    }
}

/* ==========================================================================
   Mobile-Specific Overrides (max-width)
   - Primarily used here to show the YouTube column on mobile.
   ========================================================================== */
@media (max-width: #{variables.$mobile-breakpoint - 1px}) {
    /* Target screens *below* the desktop breakpoint */

    .youtube-column {
        display: block; /* Show the YouTube column */
        /* Other styles like width, margin are inherited from base .youtube-column */
    }

    /* If .order-here needs to be shown on mobile, uncomment this */
    /*
    .order-here {
        display: block;
        margin: 70px auto 15px auto;
        width: 90%; // Example width
        img {
            max-width: 45%;
            margin: 0 auto;
        }
         &:hover {
             transform: none; // Optional: Disable hover on mobile
        }
    }
    */
}

/* ==========================================================================
   Desktop Styles (min-width)
   ========================================================================== */
@media (min-width: variables.$mobile-breakpoint) {
    /* --- Desktop Body & Overall Layout --- */
    body {
        background-attachment: fixed; /* Fixed background for parallax effect */
        /* display: flex, etc., inherited from base */
        /* Remove padding here; handled by the container */
    }

    .contact-desktop {
        display: block;
    }

    .contact-mobile {
        display: none;
    }

    .contact-email {
        font-family: "adobe-garamond-pro", serif;
        font-style: oblique;
        font-weight: bold;
        transform: skewX(-12deg);
        font-size: clamp(0.4rem, 0.7vw, 18px);
        line-height: 1.2;
        letter-spacing: 0.15em;
        color: white;
        text-align: center;
        margin-top: 3rem;
        font-variant-ligatures: common-ligatures;
        -webkit-font-smoothing: antialiased;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

        .contact-label {
            font-style: oblique;
            transform: skewX(-15deg);
            color: white;
            text-decoration: none;
            text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
        }

        a {
            color: white;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .desktop-layout-container {
        display: flex; /* Activate 3-column layout */
        width: 100%;
        min-height: 100vh; /* Ensure container tries to fill viewport height */
        flex-grow: 1; /* Allow container to grow if body is flex */
        align-items: stretch; /* Make columns equal height */
        padding: 20px; /* Outer padding around the 3 columns */
        box-sizing: border-box;
    }

    /* --- Desktop Column Definitions & Order --- */
    .main-content-column,
    .social-links-column,
    .youtube-column {
        display: flex; /* Enable flex properties for alignment within columns */
        flex-direction: column;
        align-items: center; /* Center content horizontally within each column */
        justify-content: center; /* Center content vertically */
        padding-top: 3vh; /* Consistent vertical padding top */
        padding-bottom: 3vh; /* Consistent vertical padding bottom */
        box-sizing: border-box;
        /* Add min-height if columns might become too squished vertically */
        /* min-height: 450px; */
    }

    .youtube-column {
        /* Left Column */
        flex: 1; /* Takes 1 part of the space */
        order: 1;
        /* Add padding on the *right* side to create space next to the center column */
        padding-left: 70px; /* Adjust value as needed for visual balance */
        /* Remove any specific padding-left */
        padding-right: 0px;
    }

    .main-content-column {
        /* Center Column */
        flex: 2; /* Takes 2 parts of the space (wider) */
        order: 2;
        justify-content: space-between; /* Distribute space between items vertically */
        /* Add padding *before* nested rules */
        padding-left: 15px; /* Small padding to prevent sticking to side columns */
        padding-right: 15px; /* Small padding to prevent sticking to side columns */
        /* Add margin to direct children for consistent vertical spacing */
        & > * + * {
            margin-top: 2vh; /* Adjust vertical gap between elements */
        }
    }

    .social-links-column {
        /* Right Column */
        flex: 1; /* Takes 1 part of the space */
        order: 3;
        /* Add padding on the *left* side to create space next to the center column */
        padding-left: 0px; /* Use the SAME value as .youtube-column's padding-right */
        /* Remove any specific padding-right */
        padding-right: 70px;
    }

    /* --- Desktop Specific Element Styling --- */

    /* Hide mobile-only elements */
    .purchase-stream-divider-container {
        display: none;
    }

    /* Show desktop-only elements */
    .order-here {
        display: block;
        margin: 0; // Ensure spacing is controlled by parent
        text-align: center; // Center content inside, particularly images
        transition: transform 0.3s ease; // Smooth transition effect for scale

        picture {
            display: block; // Ensure proper layout structure
            width: 100%; // Allow picture to take full available width
            img {
                max-width: 30%; // Allow image to scale responsively
                height: auto; // Maintain aspect ratio
                object-fit: contain;
                margin: 0 auto; // Center the image
            }
        }

        &:hover {
            transform: scale(1.05); // Slight scale effect upon hover
        }
    }

    /* Adjust sizing and layout for elements within columns */

    .album-announcement {
        text-align: center;
        margin: 30px auto 0 auto;
        transition: transform 0.3s ease;

        picture {
            display: inline-block; // Ensures the picture behaves like an inline element

            img {
                max-width: 50%; // Specific mobile size, or adjust as needed
                margin: 5px auto;
                height: auto; // Maintain aspect ratio

                // Existing hover effect
                &:hover {
                    transform: scale(1.05);
                }
            }
        }
    }

    .album-cover-container {
        img {
            max-width: 100%;
            max-height: 55vh; /* Prevent getting too tall */
            object-fit: contain;
            margin: 0 auto; /* Center image */
        }
    }

    .purchase-links-container {
        margin: 0 auto; /* Keep centered */
        width: 100%; /* Take full width of column for centering links */
    }

    .purchase-links {
        gap: 15px; /* Slightly larger gap on desktop */
        padding: 5px 0;
    }

    .purchase-links {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;
        padding: 10px 0;

        a {
            display: inline-block;

            picture {
                display: inline-block;

                img {
                    max-width: 100%;
                    height: auto;
                    display: block;
                    transition: transform 0.2s ease;

                    &:hover {
                        transform: scale(1.1) translateY(-5px);
                    }
                }
            }
        }
    }

    .social-links-container {
        margin: 0; /* Reset mobile margin */
        width: auto; /* Allow container to size naturally */
        max-width: 100%; /* Prevent overflow */

        .follow-us {
            img {
                max-width: 60%; /* Slightly larger on desktop? Adjust as needed */
                max-height: 12vh;
                object-fit: contain;
                margin: 0 auto;
            }
            /* Hover inherited */
        }
    }

    .social-links {
        gap: 15px; /* Slightly larger gap */
        padding: 10px;

        /* Style for the spacer div to force wrap */
        .spacer {
            flex-basis: 100%; /* Take full width */
            height: 0; /* No height */
            margin: 0; /* No extra space */
            padding: 0;
        }
    }

    .social-links a img {
        /* Responsive width: min 30px, preferred 3.5vw, max 50px */
        width: clamp(30px, 3.5vw, 50px);
        height: auto;
        aspect-ratio: 1 / 1;
        max-height: 6vh;
        object-fit: contain;
        /* Transition inherited */
    }

    .youtube-column {
        .video-announcement {
            text-align: center;
            padding-bottom: 15px; /* Space below image */
            transition: transform 0.3s ease;

            picture {
                display: inline-block; // Ensures the block nature if needed

                img {
                    max-width: 80%; // Specific mobile size, and adjusted as needed for other breakpoints
                    margin: 0 auto;
                    height: auto; // Maintain aspect ratio
                    transition: transform 0.2s ease; // Ensure smooth hover effects

                    &:hover {
                        transform: scale(1.05); // Slight scaling on hover
                    }
                }
            }
        }

        .youtube-container-inner {
            max-width: 90%; /* Max width within the column */
            width: 100%; /* Take available width up to max-width */
            border: 5px solid transparent; /* Base for animation */
            animation: pulse-border 3s infinite; /* Add pulsing border */
            margin: 0 auto; /* Center the container */
            iframe {
                /* Styles inherited (display block, aspect-ratio, etc.) */
            }
        }
    }
} /* --- End of Desktop Media Query --- */

/* ==========================================================================
   Animations
   ========================================================================== */

@keyframes pulse-border {
    0% {
        border-color: rgba(255, 99, 71, 0.8); /* Start color */
    }
    50% {
        border-color: rgba(255, 165, 0, 0.8); /* Mid color */
    }
    100% {
        border-color: rgba(255, 99, 71, 0.8); /* End color (loop) */
    }
}

/* ==========================================================================
   Error Page Styles (Moved from inline)
   ========================================================================== */

.error-page-container {
    font-family: "adobe-garamond-pro", serif;
    font-style: oblique;
    font-weight: bold;
    transform: skewX(-12deg);
    font-size: clamp(0.4rem, 0.7vw, 18px);
    line-height: 1.2;
    letter-spacing: 0.15em;
    color: white;
    text-align: center;
    margin-top: 3rem;
    font-variant-ligatures: common-ligatures;
    -webkit-font-smoothing: antialiased;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);

    a {
        color: white;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    h1 {
        margin: 30px 0;
        font-size: 4em;
        line-height: 1;
        letter-spacing: -1px;
    }
    p {
        font-size: 1.1em;
    }
    a {
        /* Style link back home if you add one */
        color: #ffcc00; /* Example link color */
        text-decoration: underline;
    }
}
