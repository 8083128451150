// --- Responsive Breakpoints ---
$mobile-breakpoint: 600px; /* The width above which desktop styles apply */

// --- Icon Sizes ---
/* Defines base sizes for icons, can be overridden or scaled */
$social-icon-size-desktop: 60px;
$social-icon-size-mobile: 50px;
$purchase-icon-size-desktop: calc($social-icon-size-desktop * 2.8); /* Example calculation */
$purchase-icon-size-mobile: 85px;

// --- Colors and Effects ---
/* Not all of these seem actively used in _base.scss but kept for potential use */
$icon-backdrop-color: rgba(255, 255, 255, 0.5); /* Semi-transparent white */
$social-icon-gap: 20px; /* Base gap, can be overridden */
$text-color: white; /* Default text color (though body has no text) */
$text-shadow-color: rgba(0, 0, 0, 0.7);
$text-shadow: 4px 4px 4px $text-shadow-color;
$album-announcement-stroke-color: black; /* For text stroke, if needed */
$album-announcement-stroke-width: 0.00em; /* For text stroke, if needed */

// --- Misc. Sizes ---
$tir-icon-size: 120px; /* Example specific icon size, maybe used elsewhere */
